import React from 'react'
import axios from 'axios'

import Team from '../components/teamList'

export default class TeamPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();
      wow.init();
    } catch (e) {
      console.error(e);
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/about/getbio`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => {})
  }

  render() {
    const { data } = this.state
    let about = ''

    if (data) {
      about = data.Bio
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>A Nossa Equipa</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <Team />
      </div>
    )
  }
}
